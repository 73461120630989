import React from "react"
import Layout from "../components/layout"
// import { Link } from "gatsby"

const AboutPage = () => {

    return (
        <Layout page='About'>
            <h1>About</h1>
            <h4>
            I am a software developer living in Sunny Las Vegas.<br/>
            Experience in full stack development, product management, and lead engineer roles.<br/>
            Open to all local and remote work options and availble to travel!<br/>
            </h4>

        </Layout>
    )

}

export default AboutPage